<template  v-if="banner.length > 0">
<div style="width:100%;height:100%;">
<banner1 :type="type" :positions="positions"  v-if="banner.length > 0" :dataurl="banner" @EventBack='returnmethod'></banner1>
</div>
</template>
<script>
	import axiosx from '../axios/postx';
	import axios from '../axios/request';
	import banner1 from './banner1.vue';
	export default {
		props: {
			positions: {
				type: String,
				default: 'none'
			},
			heightNew: {
				type: String
			},
			type: {
				type: Number,
				default: 0
			},
			abs:{
				type: String
			},
			datatype: {
				type: String,
				default: "0"
			},
			dataurl:{
				type: Object,
				default: {}
			}
		},
		data() {
			return {
				classshow: false,
				banner:[],
				// appheight:100
				//oneClass:getApp().globalData.viewModel
			}
		},
		watch:{
			dataurl:function(newVal,oldVal){
				var banner = [];
				for(var k in newVal){
					banner.push({linkurl:"",picurl:newVal[k]})
				}
				if(banner.length == 2){
					banner[2] = banner[0];
					banner[3] = banner[1];
				}
				this.banner = banner;
			}
		},
		components: {
			banner1
		},
		created() {
			console.log(this.abs,'abs')
			if(this.datatype == 0){
				axios('news/banner.json',{abs:this.abs})
				.then((res)=>{
					let data = res.data.data;
					var banner = [];
					// banner = data[this.abs];
					for(var k in data[this.abs]){
						banner.push(data[this.abs][k]);
					}
					if(banner.length == 2){
						banner[2] = banner[0];
						banner[3] = banner[1];
					}
					this.banner = banner;
					if(banner.length==0){
						this.returnmethod();
					}
				});
			}else if(this.datatype == 1){
				
				var banner = [];
				for(var k in this.dataurl){
					banner.push({linkurl:"",picurl:this.dataurl[k]})
				}
				if(banner.length == 2){
					banner[2] = banner[0];
					banner[3] = banner[1];
				}
				
				this.banner = banner;
				if(banner.length==0){
					this.returnmethod();
				}
			}

			
		},
		methods:{
			returnmethod(){
				if(this.banner.length>0){
					console.log(this.abs,'返回banner1');
					this.$emit('EventBack',this.abs,'1')
					this.$parent.setbannershow && this.$parent.setbannershow(this.abs,1)
				}else{
					console.log(this.abs,'返回banner0');
					this.$emit('EventBack',this.abs,'0')
					this.$parent.setbannershow && this.$parent.setbannershow(this.abs,0)
				}
			}
		},
		mounted() {
			// console.log("this.heightNew" + this.heightNew)
		}
	}
</script>
<style>
	.one {
		width: 100%;
		height: 100%;
	}
	
	.one img {
		width: 100%;
		height: 100%;
	}
	
	.swiper_banner {
		width: 100%;
		height: 100%;
	}
	.swiper_banner .el-carousel__container{
		width: 100%;
		height: 99%!important;
		overflow: hidden;
		
	}
	.swiper_banner_item {
		width: 100%;
		height: 101%;
	}
	.swiper_banner_item a{
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		height: 100%;
	}
	.swiper_banner_item a img{
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		height: 100%;
	}
</style>