// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import News from '../views/News/NewList.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/newsinfo',
    name: 'newsinfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News/NewInfo.vue')
  },
  {
    path: '/videoinfo',
    name: 'videoinfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News/VideoInfo.vue')
  },
  {
    path: '/Rank',
    name: 'Rank',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Rank/index.vue')
  },
  {
    path: '/Rankinfo',
    name: 'Rankinfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Rank/detail.vue')
  },
  // {
  //   path: '/TecStatistics',
  //   name: 'TecStatistics',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/TecStatistics/index.vue')
  // },
  {
    path: '/TecStatisticsinfo',
    name: 'TecStatisticsinfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TecStatistics/info.vue')
  },
  {
    path: '/MatchList',
    name: 'MatchList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MatchList.vue')
  },
  {
    path: '/Match',
    name: 'Match',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Match/index.vue')
  },
  {
    path: '/Matchnow',
    name: 'Matchnow',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Match/now.vue')
  },
  {
    path: '/NewMod',
    name: 'NewMod',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News/NewMod.vue')
  },
  
  {
    path: '/Enterprise',
    name: 'Enterprise',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Enterprise.vue')
  },
  {
    path: '/Score',
    name: 'Score',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Score.vue')
  },
  {
    path: '/Lists',
    name: 'Lists',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/Lists.vue')
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SignUp/index.vue')
  },
  {
    path: '/Player',
    name: 'Player',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Player/Player.vue')
  },
  {
    path: '/PlayerInfo',
    name: 'PlayerInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Player/info.vue')
  },
  {
    path: '/PlayerSearch',
    name: 'PlayerSearch',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Player/search.vue')
  },
  {
    path: '/PlayerVideo',
    name: 'PlayerVideo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Player/video.vue')
  },
  {
    path: '/des',
    name: 'des',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/News/clpga.vue')
  },
  {
    path: '/openwindow',
    name: 'openwindow',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/openwindow.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  
  console.log('sssss')
  let flag = to.path.toLowerCase();
  let query = to.query;


  if(query.lang == 'cn')
  {
    localStorage.setItem('lang',query.lang)
  }
  else if(query.lang == 'en')
  {
    localStorage.setItem('lang',query.lang)
  }


  if(flag == '/' && !query.lang)
  {
    // console.log(navigator.language,'langflag');
    let navLang = navigator.language.substring(0, 2)
    var langflag = 'en';
    if (navLang == 'zh') {
      langflag = 'cn';
    } else {
      langflag = 'en'
    }
    console.log(langflag);
    // return false;
    localStorage.setItem('lang',langflag)
    next({ path: '/', query: {lang:langflag}})
  }

  // if(flag=='/score/posc' && to.query.check != '1')
  // {
  //   let query = to.query;
  //   query.path = flag;
  //   next({ path: '/go', query: query})
  // }
  else if(flag == '/score/index' && to.query.check != '1' && to.query.mtid <= 0 && to.query.shouchange !=1) //跳转到排名首页
  {
    let query = to.query;
    query.path = flag;
    next({ path: '/go', query: query})
  }
  else if(flag == '/home' && to.query.check != '1'  && to.query.mtid <= 0 )   //跳转到分组
  {
    let query = to.query;
    query.path = flag;
    next({ path: '/go', query: query})
  }
  else
  {
    if(flag !='/newsinfo' || flag !='/videoinfo')
    {
      document.querySelector('meta[name="keywords"]').setAttribute('content', '女子中巡,CLPGA,女子中巡官网,女子高尔夫赛事,女子中巡赛官网');
				document.title = '女子中巡,CLPGA,女子中巡官网,女子高尔夫赛事,女子中巡赛官网';
    }
    next();
  }
  
})

export default router
