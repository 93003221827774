<template>

	<el-row v-if="hide != '1'" type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_footer1_tit_box">
				<div class="lf_foot_tit_text1">
					{{type == 'xhs'?'巡回赛新闻':''}}
					{{type == 'match'?(matchname?matchname:''):''}}
					{{type == 'good'?'头条新闻':''}}
					{{type == 'search'?search:''}}
					{{title?langs[title]:""}}
				</div>
				<div class="lf_foot_tit_line"></div>
				<div class="lf_foot_tit_text2">
					{{type == 'xhs'?'Race Station News':''}}
					{{type == 'good'?'Wonderful News':''}}
					{{title?langs[title+"_en"]:""}}
				</div>
			</div>
		</el-col>
	</el-row>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">

			<div class="lf_News_list_box_all" v-for="b in banner">
				<div class="lf_News_list_box lazyLoad">
					 <router-link target="_blank" :to="{path:'/newsinfo',query:{id:b.ID,lang:systemlang}}">
						<!-- <img class="lf_News_cons_img" v-if="b.ArticleImg" style="object-fit: cover;height: 100%;" v-lazy="b.ArticleImg"  alt=""> -->
						<div class="lf_News_cons_img"  style="display:flex;" v-if="b.ArticleImg">
							<img  style="object-fit: cover;height: 100%;margin:0 auto;"  v-lazy="b.ArticleImg"  alt="">
						</div>
						
						<img class="lf_News_cons_img" v-else src="../assets/images/u121245.png" />
							<div class="lf_News_cons_box">
								<div class="lf_News_cons_box_1" v-html="b.ArticleTitle"></div>
								<div class="lf_News_cons_box_2" v-html="b.ArticleBrief"></div>
								<div class="lf_News_cons_box_3">{{b.ArticleTime}}</div>
							</div>
						</router-link>
				 
				</div>
			</div>

		</el-col>
	</el-row>
	<!-- <router-link :to="{path:'/news',query:{lang:systemlang}}">
		<div class="lf_footer1_btn_go">

			<a href="/#/news">
				{{langs.More}}
			</a>
			<img src="../assets/images/u100p002.png" alt="">

		</div>
	</router-link> -->
	<el-row type="flex" justify="center" v-if="ishomepage!=1&&show && next_page!==0&&banner.length>0">
		<el-col :xs="24" :sm="24" :md="24">

			<div v-on:click="gonext" class="lf_footer1_btn_go lf_footer1_btn_go_11 hand">
				{{langs.psearch_more}}
			</div>

		</el-col>
	</el-row>
	<el-row type="flex" justify="center" v-if="ishomepage==1">
		<el-col :xs="24" :sm="24" :md="24">
			<router-link :to="{path:'/news',query:{lang:systemlang}}">
				<div class="lf_footer1_btn_go hand">
					{{langs.More}}
					<img src="../assets/images/u100p002.png" alt="">
				</div>
			</router-link>
		</el-col>
	</el-row>

	<el-row type="flex" justify="center" v-if="ishomepage!=1&&show && banner.length <= 0&&showempty==1">
		<el-col :xs="24" :sm="24" :md="24">

				<empty></empty>
		</el-col>
	</el-row>

</template>

<script>
		import empty from "./empty.vue";
	import {
		throwStatement
	} from '@babel/types';
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	import axios from '../axios/request';
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				banner: [],
				page: 1,
				next_page: 'null',
				matchname: false,
				search: '',
				show:false,
				// searchstring:""
			};
		},
		name: 'NewList',
		props: {
			msg: String,
			type: String,
			id: String,
			year: String,
			pl_name: String,
			mt_name: String,
			mt_id: String,
			hide: String,
			pagehome: String,
			category:{
				type: String,
				default: "0"
			},
			limit:{
				type: String,
				default: "8"
			},
			front:{
				type: String,
				default: "0"
			},
			showempty:{
				type: String,
				default: "1"
			},
			searchstring:{
				type: String,
				default: ""
			},
			ishomepage:{
				type:String,
				default: 0
			},
			title:{
				type:String,
				default: ""
			},
			starttime:{
				type:String,
				default: ""
			},
			issort:{
				type: String,
				default:""
			}
		},
		components: {
			empty
		},
		watch: {
			searchstring(newVal,oldVal){
				this.getData(1)
			}
		},
		created() {
			//获取banner
			let s = [];
			if (this.year && this.year > 0) {
				s.push(this.year);
			}
			if (this.mt_name && this.mt_name != '' && this.mt_name != undefined) {
				s.push(this.mt_name);
			}
			if (this.pl_name && this.pl_name != '' && this.pl_name != undefined) {
				s.push(this.pl_name);
			}
			this.search = s.join('-');
			this.getData();
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
		},
		methods: {
			// getData(changebanner=0,searchstring="") {
			// 	if (this.next_page === 0&&changebanner==0) {
			// 		return false;
			// 	}
			// 	if (this.next_page == 'null'||changebanner==1) {
			// 		this.next_page = 1;
			// 	}
			// 	let type = this.type;
			// 	if (type == 'match') {
			// 		type = 'match_g'; //真新闻
			// 	}

			// 	let params = {
			// 		id: this.id,
			// 		page: this.next_page,
			// 		limit: this.limit,
			// 		category: this.category,
			// 		front: this.front
			// 	};
			// 	if (type == 'search') {
			// 		params.year = this.year;
			// 		params.mt_id = this.mt_id;
			// 		params.pl_name = this.pl_name;
			// 		if(searchstring!=""){
			// 			this.searchstring = searchstring;
			// 		}
			// 		params.searchstring = this.searchstring;
			// 	}
			// 	axios('news/' + type + '.json', params)
			// 		.then((res) => {
			// 			// console.log('next',res.data.page.next);
			// 			let data = res.data.data;
			// 			this.next_page = res.data.page.next;
			// 			if (res.data.matchname) {
			// 				this.matchname = res.data.matchname;
			// 			}
			// 			if(changebanner == 1){
			// 				this.banner = [];
			// 			}
			// 			for (let s in data) {
			// 				if(this.searchstring != ""){
			// 					data[s].ArticleTitle = data[s].ArticleTitle.replace(this.searchstring,"<b style='color:red;'>"+this.searchstring+"</b>")
			// 					data[s].ArticleBrief = data[s].ArticleBrief.replace(this.searchstring,"<b style='color:red;'>"+this.searchstring+"</b>")
			// 				}
			// 				this.banner.push(data[s]);
			// 			}
			// 			this.show = true;
			// 			this.$emit("setPage",res.data.page)
			// 		});
			// },
			getData(changebanner=0) {
				if (this.next_page === 0&&changebanner==0) {
					return false;
				}
				if (this.next_page == 'null'||changebanner==1) {
					this.next_page = 1;
				}
				let type = this.type;
				if (type == 'match') {
					type = 'match_g'; //真新闻
				}

				let params = {
					id: this.id,
					page: this.next_page,
					limit: this.limit,
					category: this.category,
					front: this.front,
					issort: this.issort
				};
				console.log("this.mt_id",this.mt_id)
				if (type == 'search') {
					params.year = this.year;
					params.mt_id = this.mt_id;
					params.pl_name = this.pl_name;
					params.searchstring = this.searchstring;
					param.pagehome = this.pagehome;
					if(this.next_page == 1){
						params.starttime = this.starttime;
					}
				}
				axios('news/' + type + '.json', params)
					.then((res) => {
						// console.log('next',res.data.page.next);
						let data = res.data.data;
						console.log('ssss',data,res.data.page);
						this.next_page = res.data.page.next;
						if (res.data.matchname) {
							this.matchname = res.data.matchname;
						}
						if(changebanner == 1){
							this.banner = [];
						}
						for (let s in data) {
							if(this.searchstring != ""){
								data[s].ArticleTitle = data[s].ArticleTitle.replace(this.searchstring,"<b class='redcolor' >"+this.searchstring+"</b>")
								data[s].ArticleBrief = data[s].ArticleBrief.replace(this.searchstring,"<b class='redcolor' >"+this.searchstring+"</b>")
							}
							this.banner.push(data[s]);
						}
						this.show = true;
						this.$emit("setPage",res.data.page)
					});
			},
			gonext() {
				this.getData();
			}
		}

	}
</script>
<style>
	.redcolor{
		color:#C0153E;
	}
	.lf_News_list_box_all:hover .redcolor{
		color: #fff;
	}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.lf_footer1:nth-last-of-type(1) {
		border-bottom: none;
	}

	.lf_News_list_box {


		cursor: pointer;
		align-items: center;

		padding: 0.3rem 0 0.3rem 0.3rem;

		overflow: hidden;
		


		margin-bottom: 0.02rem;



	}

	.lf_News_list_box_all :hover {

		background-color: #C0153E;

		

	}
	.lf_News_list_box_all :hover .lf_News_cons_box_1,.lf_News_list_box_all :hover .lf_News_cons_box_2,.lf_News_list_box_all :hover .lf_News_cons_box_3 {
	
		color: #fff !important;
	
	}


	.lf_News_cons_img {

		width: 2.14rem;

		height: 1.2rem;
		

		float: left;

	}



	.lf_News_list_box_select {

		background-color: #C0153E;

	}



	.lf_News_cons_box {

		float: left;

		width: 68%;

		margin-left: 0.4rem;
		

	}




	.lf_News_cons_box_1 {
		font-size: 0.28rem;
		width: 98%;
		white-space: nowrap;
		overflow: hidden;
		height: 0.3rem;
		line-height: 0.3rem;
		text-overflow: ellipsis;
		margin: 0.08rem 0;
		color: #333;
		font-weight: bold;
	}

	.lf_News_cons_box_2 {
		font-size: 0.26rem;
		width: 98%;
		white-space: nowrap;
		overflow: hidden;
		height: 0.3rem;
		line-height: 0.3rem;
		text-overflow: ellipsis;
		margin: 0.08rem 0;
		color: #666666;
	}

	.lf_News_cons_box_3 {
		font-size: 0.24rem;
		color: #666666;
		height: 0.3rem;
		line-height: 0.3rem;
	}

	.lf_News_list_box_select .lf_News_cons_box_1 {
		color: #fff;
	}

	.lf_News_list_box_select .lf_News_cons_box_2 {
		color: #fff;
	}

	.lf_News_list_box_select .lf_News_cons_box_3 {
		color: #fff;
	}

	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.15rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 1.37rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}
	.lf_footer1_btn_go_11{
		width: 100%!important;
		height: 0.62rem;
		background: inherit;
		background-color: rgba(247, 247, 247, 1)!important;
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.28rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0.5rem auto;
		color: #666!important;
	}

	.lf_footer1_btn_go_11 a {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
	}
	.lf_footer1_btn_go {
		width: 2.2rem;
		height: 0.62rem;
		background: inherit;
		background-color: #C0153E;
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.24rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0.5rem auto;
		color: #fff;
		position: relative;
	}
	.lf_footer1_btn_go img{ position: absolute;height: 0.26rem;width: 0.28rem;transform: rotate(180deg);right: 0.1rem;top: 0.18rem;}
	
	.lf_footer1_btn_go a {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
	}

	@media screen and (max-width: 768px) {
		.lf_News_list_box_all{
			width: 100%;
			margin: 0 auto;
			height: 1.7rem;
			overflow: hidden;
		
		}
		.lf_News_cons_box{
			width: 65%;
	
			float: left;
			overflow: hidden;
			margin-left: 0.2rem;
			height: 1.2rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		
		.lf_News_cons_box_1 {
			margin-top: 0;
	
			margin-bottom: 0.05rem;
		}
		
		.lf_News_cons_box_2 {
			margin-top: 0;
			margin-bottom: 0.08rem;
		}
		
		.lf_News_cons_box_3 {
		margin-top: 0;
		margin-bottom: 0.01rem;
		}
		
		.lf_footer1 {
			width: 100%;
			margin: 0 auto;
		}

		.lf_link_con_box_img {
			width: 130px;
			height: 65px;
			margin: 0 auto;
		}

		.lf_News_cons_img {
			width: 2rem;
			height: 1.12rem;
			margin: 0 auto;
			margin-left: 0.2rem;
		}

		.lf_News_list_box {
			padding: 0.3rem 0;
		}


		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-size: 0.26rem;
		}

		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-size: 0.26rem;
		}

		.lf_footer1_btn_go {
			height: 0.6rem;
			font-size: 0.24rem;
		}
		.lf_footer1_btn_go_11{
			width:96%!important;
			margin-left: 0.1rem;
			font-size: 0.24rem;
		}
	}
</style>