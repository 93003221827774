<template>
	
	<div class="empty">
		<div v-if="systemlang == 'cn'" class="empty_zi">暂无相关内容，先看看别的吧~</div>
		<div v-if="systemlang != 'cn'" class="empty_zi">There's no relevant info~</div>
		<img  src="../assets/images/u27900.png"/>
	</div>
</template>

<script>
// @ is an alias to /src
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	
	//这里就是更新后的路径，以前是import 'swiper/css'

	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				
			}
		},
		props: {

		},
		name: 'Home',
		components: {
		
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);

			//获取奖金榜

		},
		setup() {
		},
		methods: {

		}

	}
</script>


<style>
	.empty{
		width: 100%;
		text-align: center;
		overflow: hidden;
		margin-top: 1rem;
		background: #fff;
	}
	.empty_zi{
		height: 0.27rem;
		font-size: 0.24rem;
		line-height: 0.27rem;
		color: #e9e8ee;
	}
	.empty img {
		width: 5rem;
		height: 5rem;
		margin: 0 auto;
	}
</style>